import React, { useEffect, useState } from 'react';
import { Paper, TextField, Button, MenuItem, CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Checkbox, FormControlLabel, TextareaAutosize } from '@mui/material';
import moment from 'moment';
import { getClientSlots, confirmClientSlots } from '../../../store/actions/therapist.action';
import { useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { addBookingActionLog } from '../../../store/actions/booking.action';
import { hasAdminAndSuperAdminAccess } from '../UserRolesConfig';
import { fetchRemarkTags } from '../../../store/actions/faq.action';

const AllotDateV2 = (props) => {
  const role = JSON.parse(localStorage.getItem('userData'))?.user?.role;
  const user = JSON.parse(localStorage.getItem('userData'))?.user;
  const [activeOption, setActiveOption] = useState(null);
  const [activeOption1, setActiveOption1] = useState(null);
  const [selectedCenter, setSelectedCenter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [remarkTags, setRemarkTags] = useState([]);
  const [selectedRemarkTag, setSelectedRemarkTag] = useState(null);
  const [customRemarks, setCustomRemarks] = useState('');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const clientInfo = props?.body;
  const [timeSlot, setTimeSlot] = useState([]);
  const params = useParams();
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const addUserActivity = async (data) => {
    const body = {
      session_schedule_id: params?.sessionScheduleId,
      dashboard_user_id: user?.id,
      dashboard_user_name: user?.name,
      operation_type: data.operation_type,
      operation_string: data.operation_string,
    };
    try {
      await addBookingActionLog(body);
    } catch (error) {
      console.error("Error adding user log:", error);
    }
  };

  let centerList = useSelector((state) => state.center?.centerList?.centers);

  useEffect(() => {
    setBooking((prevBooking) => ({
      ...prevBooking,
      previousSlotDate: clientInfo?.slotDate,
      previousSlotTime: clientInfo?.slotTime,
      city: clientInfo.city,
      productId: clientInfo?.productId,
      clientLat: clientInfo?.clientLat,
      clientLong: clientInfo?.clientLong,
      sessionScheduleId: params?.sessionScheduleId,
    }));
  }, [params, clientInfo]);

  const [booking, setBooking] = useState({
    newSlotDate: '',
    newSlotTime: {
      startTime: '',
      endTime: '',
    },
    previousSlotDate: '',
    previousSlotTime: '',
    productId: '',
    clientLat: '',
    clientLong: '',
    sessionScheduleId: '',
    remarkId: '',
    customRemarks: '',
  });

  const getTimeSlots = async (date, index) => {
    try {
      setIsLoading(true);
      setActiveOption(index);
      setBooking((prevBooking) => ({ ...prevBooking, newSlotDate: date }));

      const requestData = {
        slotDate: date,
        city: clientInfo?.city,
        productId: clientInfo?.productId,
        clientLat: clientInfo?.clientLat,
        clientLong: clientInfo?.clientLong,
        clientId: clientInfo?.clientId,
        isDashboard: true,
        centerId: selectedCenter || null,
      };
      const response = await getClientSlots(requestData);
      if (response?.status === 200) {
        setIsLoading(false);
        const currentDate = new Date();
        const currentTime = currentDate.toTimeString().slice(0, 5);
        let currentSlots = response?.data?.slots;
        if (currentDate.toISOString().slice(0, 10) === date) {
          currentSlots = currentSlots.filter(slot => slot.clientSlotEndTime > currentTime);
        }
        setTimeSlot(
          currentSlots.sort((a, b) => {
            return a.clientSlotStartTime.localeCompare(b.clientSlotStartTime);
          }),
        );
      } else {
        console.error('Failed to fetch time slots:', response?.status);
      }
    } catch (error) {
      console.error('An error occurred while fetching time slots:', error);
    }
  };

  function getNextSevenDays(today) {
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const nextDate = new Date(today);
      nextDate.setDate(today.getDate() + i);
      const year = nextDate.getFullYear();
      const month = String(nextDate.getMonth() + 1).padStart(2, '0');
      const day = String(nextDate.getDate()).padStart(2, '0');
      const formattedDate = `${year}-${month}-${day}`;
      dates.push({ id: i + 1, date: formattedDate });
    }
    return dates;
  }

  const getRemarkTags = async () => {
    try {
      const response = await fetchRemarkTags();
      if (response?.status?.code === 200) {
        console.log(response?.tags)
        setRemarkTags(response?.tags || []);
      } else {
        console.error('Failed to fetch remark tags:', response?.status);
      }
    } catch (error) {
      console.error('An error occurred while fetching remark tags:', error);
    }
  };

  const confirmBookingSlot = async (item, index) => {
    setActiveOption1(index);
    setBooking((prevBooking) => ({
      ...prevBooking,
      newSlotTime: {
        startTime: item.clientSlotStartTime,
        endTime: item.clientSlotEndTime,
      },
      centerId: selectedCenter || null,
    }));
    // Open modal for remarks
    setIsModalOpen(true);
    // Fetch remark tags
    await getRemarkTags();
  };

  const handleSlotConfirmation = async (e) => {
    e.preventDefault();
    handleCloseModal()
    if (!booking?.newSlotTime?.startTime) {
      alert('Please Select Booking time ');
      return;
    }
    const isConfirmed = window.confirm(
      'Are you sure you want to Confirm slot?',
    );
    if (isConfirmed) {
      try {
        setIsButtonDisabled(true);
        const res = await confirmClientSlots({
          ...booking,
          remarkId: selectedRemarkTag,
          customRemarks: customRemarks,
          dashboardUserId: user?.id,
          dashboardUserName:user?.name
        });
        if (res.status === 200) {
          addUserActivity({
            operation_string: `Dashboard user ${user?.name} rescheduled this booking.`,
            operation_type: "rescheduled"
          });
          alert(res.data?.status?.message);
          window.location.reload();
        }
      } catch (error) {
        console.error(
          'An error occurred while handling the submission:',
          error,
        );
        setIsButtonDisabled(false);
      }
    }
  };

  const handleCenterChange = (event) => {
    setSelectedCenter(event.target.value);
    setActiveOption(null);
    setActiveOption1(null);
    setTimeSlot([]);
  };

  const handleTagChange = (event) => {
    setSelectedRemarkTag(event.target.value);
  };

  const handleRemarkChange = (event) => {
    setCustomRemarks(event.target.value);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <Paper elevation={3} style={{ padding: '20px' }}>
      <h1 style={{ textAlign: 'center' }}>Appointment Date</h1>
      <div style={{ textAlign: 'center' }}>
        <h3>Select A Date</h3>
        <TextField
          select
          label="Choose Center"
          fullWidth
          margin="normal"
          value={selectedCenter}
          onChange={handleCenterChange}
          disabled={props.isDisabled}
          required
          sx={{
            '.MuiInputBase-root': {
              height: 50,
            },
            '.MuiInputLabel-root': {
              fontSize: 12,
            },
            '.MuiMenuItem-root': {
              fontSize: 12,
            },
          }}
        >
          {centerList && centerList.length > 0 ? (
            centerList.map((center) => (
              <MenuItem value={center.id} key={center.id}>
                {center.name}
              </MenuItem>
            ))
          ) : (
            <MenuItem value="value">Enter</MenuItem>
          )}
        </TextField>
        <div style={{ textAlign: 'center' }}>
          <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
            {getNextSevenDays(new Date())?.map((item, index) => (
              <li
                key={index}
                style={{
                  display: 'inline-block',
                  margin: '5px',
                  cursor: 'pointer',
                  background: activeOption === index ? '#ccc' : 'transparent',
                  padding: '5px 10px',
                  borderRadius: '5px',
                  fontWeight: 'bold',
                  border: '1px solid #ccc',
                }}
                onClick={() => getTimeSlots(item.date, index)}
              >
                {moment(item.date, 'YYYY-MM-DDT.SSS[Z]').format(`ddd DD`)}
              </li>
            ))}
          </ul>
          </div>
          <div style={{ margin: '30px' }}>
          {isLoading && <CircularProgress />}
        </div>
          {!isLoading && timeSlot && timeSlot.length > 0 ? (
            <>
              <ul style={{ listStyle: 'none', padding: 0, margin: 0 }}>
                {timeSlot.map((item, index) => (
                  <li
                    key={index}
                    style={{
                      display: 'inline-block',
                      margin: '5px',
                      cursor: 'pointer',
                      background:
                        activeOption1 === index ? '#ccc' : 'transparent',
                      padding: '5px 10px',
                      borderRadius: '5px',
                      fontWeight: 'bold',
                      border: '1px solid #ccc',
                    }}
                    onClick={() => confirmBookingSlot(item, index)}
                  >
                    <span>
                      {`${item.clientSlotStartTime}-${item.clientSlotEndTime}`}
                    </span>
                  </li>
                ))}
              </ul>
              {(hasAdminAndSuperAdminAccess(role) && isButtonDisabled ) && (
                <button
                  disabled={props?.isDisabled || isButtonDisabled}
                  style={{
                    padding: '10px 20px',
                    fontSize: '16px',
                    backgroundColor:
                      props?.isDisabled || isButtonDisabled ? 'gray' : 'blue',
                    color: 'white',
                    border: 'none',
                    borderRadius: '5px',
                    cursor: 'pointer',
                    marginTop: '20px',
                  }}
                  // onClick={handleSlotConfirmation}
                >
                  {isButtonDisabled && 
                    <CircularProgress size={24} color="inherit" />
                  }
                </button>
              )}
            </>
          ) : (
            <h3>
              {!isLoading &&
                timeSlot?.length === 0 &&
                'Please Select Booking date'}
            </h3>
          )}
      </div>

      {/* Remark Tags Modal */}
      {/* <Dialog open={isModalOpen} onClose={handleCloseModal}>
        <DialogTitle>Select a Remark Tag or Enter Custom Remarks</DialogTitle>
        <DialogContent>
          <div style={{ marginBottom: '10px' }}>
            <select
              select
              label="Remark Tags"
              value={selectedRemarkTag || ''}
              onChange={handleTagChange}
              required
              fullWidth
            >
              {remarkTags.map((tag) => (
                <MenuItem key={tag.id} value={tag.id}>
                  {tag.tag}
                </MenuItem>
              ))}
            </select>
          </div>
          <div>
            <textarea
              minRows={4}
              placeholder="Enter custom remarks"
              value={customRemarks}
              onChange={handleRemarkChange}
              required
              style={{ width: '100%' }}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal}>Cancel</Button>
          <Button type='submit' disabled={isButtonDisabled} onClick={handleSlotConfirmation}>{isButtonDisabled ?"Wait Rescheduling...":"Confirm"}</Button>
        </DialogActions>
      </Dialog> */}

<Dialog open={isModalOpen} onClose={handleCloseModal}>
  <form onSubmit={handleSlotConfirmation}>
    <DialogTitle>Select a Remark Tag or Enter Custom Remarks</DialogTitle>
    <DialogContent>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="remarkTags">Remark Tags</label>
        <select
          id="remarkTags"
          label="Remark Tags"
          value={selectedRemarkTag || ''}
          onChange={handleTagChange}
          required={!customRemarks.trim()}
          style={{ width: '100%', padding: '8px', borderRadius: '4px', border: '1px solid #ccc' }}
        >
          <option value="" disabled>Select a tag</option> {/* Placeholder option */}
          {remarkTags.map((tag) => (
            <option key={tag.id} value={tag.id}>
              {tag.tag}
            </option>
          ))}
        </select>
      </div>
      <div style={{ marginBottom: '10px' }}>
        <label htmlFor="customRemarks">Custom Remarks</label>
        <textarea
          id="customRemarks"
          rows={4}
          placeholder="Enter custom remarks"
          value={customRemarks}
          onChange={handleRemarkChange}
          required={!selectedRemarkTag}
          style={{
            width: '100%',
            padding: '8px',
            borderRadius: '4px',
            border: '1px solid #ccc',
          }}
        />
      </div>
    </DialogContent>
    <DialogActions>
      <Button onClick={handleCloseModal}>Cancel</Button>
      <Button type="submit" disabled={isButtonDisabled}>
        {isButtonDisabled ? "Wait Rescheduling..." : "Confirm"}
      </Button>
    </DialogActions>
  </form>
</Dialog>

    </Paper>
  );
};

export default AllotDateV2;
