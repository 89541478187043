import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { getToken } from '../../components/common/userLocalStorageUtils';

const BookingRescheduleLogs = ({ sessionScheduleId }) => {
  const [bookingActionLog, setBookingActionLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchRescheduleActionLogs = async () => {
      setLoading(true);
      setError(null);

      try {
        const response = await axios.get(
          `${process.env.REACT_APP_API_URL}/api/v1/admin/booking/reschedule-booking-log/${sessionScheduleId}`,
          {
            headers: {
              Authorization: `Basic ${process.env.REACT_APP_ADMIN_APP_KEY}`,
              token: getToken()
            },
          }
        );
        setBookingActionLog(response?.data?.rescheduleLogs);
      } catch (err) {
        setError('Error fetching booking logs.');
      } finally {
        setLoading(false);
      }
    };

    if (sessionScheduleId) {
      fetchRescheduleActionLogs();
    }
  }, [sessionScheduleId]);

  return (
    <div>
      <div className="table-container">
        <h3>Reschedule Logs</h3>
        {loading ? (
          <p>Loading...</p>
        ) : error ? (
          <p style={{ color: 'red' }}>{error}</p>
        ) : (
          <table className="custom-table">
            <thead>
              <tr>
                <th>S.N</th>
                <th>Remark</th>
                <th>Custom Remark</th>
                <th>Client ID</th>
                <th>Client Name</th>
                <th>Admin ID</th>
                <th>Admin Name</th>
                <th>Action Date</th>
                <th>Action Time</th>
              </tr>
            </thead>
            <tbody>
              {bookingActionLog.length > 0 ? (
                bookingActionLog.map((data, idx) => (
                  <tr key={data.id || idx}>
                    <td>{idx + 1}</td>
                    <td>{data.remark || 'N/A'}</td>
                    <td>{data.customRemarks || 'N/A'}</td>
                    <td>{data.clientId || 'N/A'}</td>
                    <td>{data.clientName || 'N/A'}</td>
                    <td>{data.adminId || 'N/A'}</td>
                    <td>{data.adminName || 'N/A'}</td>
                    <td>{new Date(data.createdAt).toLocaleDateString('en-GB')}</td>
                    <td>{new Date(data.createdAt).toLocaleTimeString('en-GB')}</td>
                  </tr>
                ))
              ) : (
                <tr>
                  <td colSpan="9" style={{ textAlign: 'center', fontWeight: 'bold' }}>No booking action logs</td>
                </tr>
              )}
            </tbody>
          </table>
        )}
      </div>
    </div>
  );
};

export default BookingRescheduleLogs;
